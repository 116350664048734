<template>
  <div class="container text">
    <div class="inner">
      <component :is="tab" />
    </div>
  </div>
</template>

<script>
import News from "@/views/News";
import About from "@/views/About";

export default {
  components: {
    News,
    About,
  },
  props: ["title", "tab"],
};
</script>
