<template>
  <div>
    <p class="centered">
      Nick Jeffrey b.1985<br />Resides in Berlin and London<br />Phone +49
      15903148859<br /><a href="mailto:nickjeffrey0@gmail.com"
        >nickjeffrey0@gmail.com</a
      >
    </p>
    <br />
    <p>MA Royal College of Art, London 2009-2011</p>
    <p>BA Chelsea College of Art &amp; Design, London 2004-2007</p>
    <br /><br />

    <p style="text-align: center">SOLO &amp; TWO PERSON EXHIBITIONS</p>
    <p class="year">2018:</p>
    <p>TBT, Ashes/Ashes, NYC.</p>
    <br />
    <p class="year">2017:</p>
    <p>
      Folk, Hannah Barry Gallery, London. Tonal Excavation, Dzialdov, Berlin.
    </p>
    <br />
    <p class="year">2016:</p>
    <p>
      Dream Divider, Horse & Pony Fine Arts, Berlin. Phoney Counter, Sydney,
      Sydney.
    </p>
    <br />
    <p class="year">2014:</p>
    <p>Shiver me timbers! Hannah Barry Gallery, London.</p>
    <br />
    <p class="year">2013 - 2009:</p>
    <p>
      Club Money, with Dorothea Stiegemann, Berlin 2013. Youth, Hannah Barry
      Gallery, London 2012. Die Verganglichkeit and the battered shed with
      Adelita Husni-Bey, Galleria la Veronica, Modica 2011. An Army of Lovers
      can be Beaten, Hannah Barry Gallery, London 2009.
    </p>
    <br /><br />

    <p style="text-align: center">GROUP EXHIBITIONS</p>
    <p class="year">2016:</p>
    <p>
      Faith &amp; Fathom, Galleria Poggiali, Florence. Sydney, Parliament
      Gardens Melbourne during Spring 1883. Dark Mimes, Ashes/Ashes, Los
      Angeles. What We All Lack, Hannah Barry Gallery, London.
    </p>
    <br />
    <p class="year">2015:</p>
    <p>
      Straight Up, Hooper Projects, Los Angeles. KARMIC THERMAL, ideal uh-huh,
      Berlin. REPEAT/PERSONA, Porous Space, Vienna.
    </p>
    <br />
    <p class="year">2014:</p>
    <p>
      ja! ideal uh-huh, Berlin. Kunstfilmtage, Düsseldorf. Pacific State,
      Caustic Coastal, London. The Marijuana Paintings, Kastanienallee 31,
      Berlin.
    </p>
    <br />
    <p class="year">2012:</p>
    <p>Lowdown (at the studio #1), Down at the Studio, Cologne.</p>
    <br />
    <p class="year">2011:</p>
    <p>
      Point. Line. Plane., Hannah Barry Gallery, London. RCA Show, London.
      Tropical Meltdown, London co curated with Dorothea Stiegemann. HYMNS,
      Colorama, London co curated with Joseph Addison.
    </p>
    <br />
    <p class="year">2010:</p>
    <p>
      TRAILS, Hannah Barry Gallery, London. RCA Interim Exhibition, London. X
      ARTWORKS IN A STRAIGHT LINE (SEEKING THE PERFECT SPHERE), Hilary Crisp,
      London. Moscow Biennale for Young Art, Moscow. Decadence and Drama, Mol
      Collection, London.
    </p>
    <br />
    <p class="year">2009:</p>
    <p>
      Nomadologies 1 &amp; 2, Galleria la Veronica, Modica. Time Flies, Hannah
      Barry Gallery, London. The Peckham Pavilion, 53rd Venice Biennale.
    </p>
    <br />
    <p class="year">2008:</p>
    <p>
      For the Trees, Hannah Barry Gallery, London. The Cellarful of Noise,
      Hannah Barry Gallery, London. From Dusk Till Dawn, The Arts Gallery,
      London. Bold Tendencies II, Peckham, London.
    </p>
    <br />
    <p class="year">2006-7:</p>
    <p>
      78 Lyndhurst Way Exhibitions Project: Colour and Line; Landscape, Film
      &amp; Photography, London. Monumental Painting Sculpture &amp; Film, Bold
      Tendencies (publication), London.
    </p>
    <br /><br />

    <p style="text-align: center">AWARDS</p>
    <p>2016 International Development Grant, Arts Council, England</p>
    <br />
    <p style="text-align: center">RESIDENCIES</p>
    <p>Hooper Projects, Los Angeles 2015. Eilean Shona, UK 2009</p>
    <br />
    <p style="text-align: center">COLLECTIONS</p>
    <p>
      Jimenez–Colon Collection. University Of The Arts London. Private
      Collections
    </p>
    <br />
    <p style="text-align: center">PUBLICATIONS</p>
    <p>Dream Divider, 2017.</p>
    <br />
    <p style="text-align: center">PRESS</p>
    <p>
      2016:
      <a
        href="http://www.daily-lazy.com/2016/07/dark-mines-at-ashesonashes-los-angeles.html"
        target="_blank"
        >Daily Lazy</a
      >,
      <a
        href="http://www.aujourdhui.pt/blog/dark-mimes-ashes-ashes"
        target="_blank"
      >
        Aujourd'hui
      </a>
      ,
      <a href="http://artviewer.org/dark-mimes-at-ashesashes/" target="_blank">
        Art Viewer
      </a>
      .
    </p>
    <p>
      2014:
      <a
        href="http://www.dazeddigital.com/artsandculture/article/22748/1/home-sweet-home/"
        target="_blank"
        >Dazed Digital</a
      >,
      <a
        href="http://aestheticamagazine.com/shiver-timbers-nick-jeffreys-hannah-barry-gallery/"
        target="_blank"
        >Aesthetica Magazine</a
      >,
      <a
        href="http://www.studiointernational.com/index.php/shiver-me-timbers-nick-jeffrey-hannah-barrygallery/"
        target="_blank"
        >Studio International</a
      >.
    </p>
    <p>
      2011:
      <a
        href="http://www.dazeddigital.com/artsandculture/article/15324/1/berlin-art-label-%E2%80%93-nick-jeffrey/"
        target="_blank"
        >Dazed Digital</a
      >.
    </p>
    <p>
      2009:
      <a
        href="http://www.ft.com/cms/s/0/54609dde-305f-11de-88e3-00144feabdc0.html/"
        >Financial Times</a
      >.
    </p>
  </div>
</template>

<script>
/* eslint-disable */
</script>
