<template>
  <div class="blog">
    <article>
      <h1>Recent</h1>
      <p>
        29.10.2022<br />
        Extractor Fields Act I, with
        <a href="https://www.softassnow.com/" target="_blank">Soft As Snow</a>,
        <a href="https://xanadu.berlin/" target="_blank">Xanadu</a>, Berlin
      </p>
      <p>
        27.11.21-09.01.22<br />
        Stampede,
        <a href="https://horseandpony.online/" target="_blank">
          Horse and Pony</a
        >, Berlin
      </p>
      <p>
        06.11.21-04.12.21<br />
        Under Your Spell,
        <a href="https://www.collectivending.com/en" target="_blank">
          Collective Ending</a
        >, London
      </p>
      <p>
        15.05.20 - 22.06.20<br />
        Isolation Booth,
        <a
          href="https://www.tzvetnik.online/article/isolation-booth-by-nick-jeffrey-at-jakob-kroon-worthing"
          target="_blank"
          >Jakob Kroon Galeri</a
        >, Worthing
      </p>
    </article>
    <hr />
    <article>
      <h1>Soundtrack & Lp</h1>
      <p>
        Isolation Booth.
        <router-link :to="{ name: 'works.audio.isolation-booth' }">
          Listen here
        </router-link>
      </p>
      <div class="three-spread">
        <div>
          <img :src="`${publicPath}isolation-booth-front-cover.jpg`" />
        </div>
        <div>
          <img :src="`${publicPath}isolation-booth-inner-sleeve.jpg`" />
        </div>
        <div>
          <img :src="`${publicPath}isolation-booth-back-cover.jpg`" />
        </div>
      </div>
      <q>
        Growing amongst the ashes, a finely weighted disembodied mist rose.
        Littered across the barn floor stood damped metal, shadow lit by the
        ever-present moon. This rural-Gothic lithesome body creaked and ached,
        echoing ancient trees in a storm. A glow of darkening suspense,
        analogous to invisible isotopes within radioactive fields of density
        transmitted from the timber structure. Dripping from the charcoal lined
        attic, a deeply nocturnal residue seeped and curdled amongst the
        strained hay strands. Time was measured by the rings of dust gathered on
        the work tabletops. A paint peeled camping stove was on its side,
        increasingly compressed by some unknown pressure: a faint light grew
        whilst a petulant wind howled.
      </q>
      <br />
      <p>
        Loss of Being LP.
        <router-link :to="{ name: 'works.audio.loss-of-being' }">
          Listen here
        </router-link>
      </p>
      <div class="three-spread">
        <div>
          <img :src="`${publicPath}loss-of-being-front-cover.jpg`" />
        </div>
        <div>
          <img :src="`${publicPath}loss-of-being-inner-sleeve.jpg`" />
        </div>
        <div>
          <img :src="`${publicPath}loss-of-being-back-cover.jpg`" />
        </div>
      </div>
      <q>
        Ecology of time-shifted as the marshland grew silent. All that could be
        heard was the sound of the illegally dumped ageless barrels tracing the
        remains of their previous lives from the now-closed factory. It has been
        said that a group of survivalists have bordered themselves up within its
        acidic rust-covered barren caverns while the pools dwell. Reeds spurned
        a new growth, as candle wax drips and wanes, the purpose of the land was
        not fit for use, decades of abandonment and decay had now formed into an
        overfilling relapsed realm.
      </q>
      <p>
        <a
          href="https://acloserlisten.com/2020/06/10/residual-drifter-isolation-booth-loss-of-being/"
          target="_blank"
        >
          A review of Isolation Booth & Loss of Being
        </a>
      </p>
    </article>
    <hr />
    <article>
      <h1>Music Video</h1>
      <p>Reciprocal Exhaustion (9:03)</p>
      <vue-plyr :options="playerOptions">
        <video
          controls
          crossorigin
          playsinline
          data-poster="https://nickjeffrey.com/files/mp4/reciprocal-exhaustion.jpg"
        >
          <source
            size="720"
            src="https://nickjeffrey.com/files/mp4/reciprocal-exhaustion.mp4"
            type="video/mp4"
          />
        </video>
      </vue-plyr>
    </article>
    <hr />
    <article>
      <h1>Publications</h1>
      <p>
        The Word is Art.
        <a
          href="https://thamesandhudson.com/catalog/product/view/id/4736/s/the-word-is-art-9780500239667/category/2/"
          target="_blank"
          >Available here</a
        >
      </p>
      <div class="two-spread">
        <div>
          <img :src="`${publicPath}the-word-is-art-front.jpg`" />
        </div>
        <div>
          <img :src="`${publicPath}the-word-is-art-back.jpg`" />
        </div>
      </div>
      <q>
        A fascinating global overview of how contemporary artists incorporate
        text and language into work that speaks to some of the most pressing
        issues of the 21st century.
      </q>
      <br />
      <p>
        We get in each other's way once in a while.
        <a
          href="https://nickjeffrey.bigcartel.com/product/we-get-in-each-other-s-way-once-in-a-while"
          target="_blank"
          >Available here</a
        >
      </p>
      <img :src="`${publicPath}we-get-in-each-others-way.jpg`" />
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL + "img/news/",
      player: null,
      playerOptions: {
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "fullscreen",
        ],
        invertTime: false,
      },
    };
  },
};
</script>

<style>
.blog article {
  margin: 1.5em 0;
}

.blog article p:not(:last-child),
.blog article q,
.blog article .two-spread,
.blog article .three-spread {
  margin-bottom: 2rem;
}

.blog article h1 {
  margin-bottom: 2.4rem;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
}

.blog article q {
  display: block;
  font-size: 14px;
  font-style: italic;
  line-height: 1.5;
}

.blog img {
  display: block;
  width: 100%;
}

.blog hr {
  border: 0.5px dashed black;
}

.blog article .two-spread,
.blog article .three-spread {
  display: flex;
  justify-content: space-between;
}

.blog article .two-spread div {
  flex: 0 0 calc(50% - 0.375em);
}

.blog article .three-spread div {
  flex: 0 0 calc(33.333% - 0.375em);
}

.blog article .plyr--video .plyr__control--overlaid {
  background: rgba(255, 255, 255, 0.28);
}

.blog article .plyr--video .plyr__control--overlaid:hover {
  background: rgba(255, 255, 255, 0.35);
}
</style>
