<template>
  <section
    ref="section"
    class="section"
    :class="{ transition, expanded, hoverable }"
  >
    <a
      :href="isTabLink ? '/' + id : null"
      class="tab-wrap"
      :class="{ expanded: isDirectoryOpen }"
      @[tabClickEvent].prevent="switchTab"
    >
      <component
        :is="isDirectoryOpen ? 'a' : 'h1'"
        :href="isDirectoryOpen ? '/' + id : null"
        class="heading"
        @[headingClickEvent].prevent="goBack"
      >
        <slot>{{ capitalized }}</slot>
      </component>
      <component
        :is="component"
        v-if="showContents"
        :tab="id"
        :is-current-tab="isCurrentTab"
        v-on:update:directory="isDirectoryOpen = $event"
      />
    </a>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Document from "@/components/Document";
import Directory from "@/components/Directory";

export default {
  components: {
    Document,
    Directory,
  },
  props: ["id", "component"],
  data() {
    return {
      isDirectoryOpen: false,
      transition: false,
      expanded: false,
      showContents: false,
    };
  },
  computed: {
    ...mapState(["group", "section", "route"]),
    ...mapGetters(["isProjectOpen", "transitionEvent", "isDesktop"]),
    capitalized() {
      return this.id.charAt(0).toUpperCase() + this.id.slice(1);
    },
    hoverable() {
      return this.isTabLink;
    },
    headingClickEvent() {
      return this.isDirectoryOpen ? "click" : null;
    },
    isCurrentTab() {
      return this.group === this.id;
    },
    isHeadingLink() {
      return this.isDirectoryOpen;
    },
    isTabLink() {
      return !this.isCurrentTab;
    },
    tabClickEvent() {
      return this.isTabLink ? "click" : null;
    },
  },
  watch: {
    isCurrentTab(val) {
      if (this.isDesktop) {
        this.transition = true;
        if (!val) this.showContents = false;
        this.transitionEvent &&
          this.$refs.section.addEventListener(
            this.transitionEvent,
            this.transitionSectionEnd
          );
        this.expanded = val;
      } else {
        this.expanded = val;
        this.showContents = val;
      }
    },
  },
  methods: {
    goBack() {
      if (this.isProjectOpen || this.route.params.internal === undefined)
        this.switchTab();
      else this.$router.back();
    },
    switchTab() {
      this.$router.push({ name: this.id });
    },
    transitionSectionEnd(e) {
      if (e.target === e.currentTarget) {
        this.transition = false;
        if (this.isCurrentTab) this.showContents = true;
        this.$refs.section.removeEventListener(
          this.transitionEvent,
          this.transitionSectionEnd
        );
      }
    },
  },
  mounted() {
    if (this.isCurrentTab) {
      this.expanded = true;
      this.showContents = true;
    }
  },
};
</script>

<style>
.section {
  position: relative;
  display: block;
  height: 4.8rem;
  text-decoration: none;
}

.section .tab-wrap {
  display: block;
  height: inherit;
  text-decoration: none;
}

.section.expanded .tab-wrap {
  cursor: default !important;
}

.section.expanded {
  height: 100%;
}

.section.transition {
  transition: all 0.5s;
}

.section.hoverable .tab-wrap {
  transition: background-color 0.2s ease-in-out;
}

.section.hoverable:hover .tab-wrap {
  background-color: var(--secondary-color);
}

.section .heading {
  position: sticky;
  display: block;
  top: 0;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 4.8rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 0.75em;
  border-bottom: 1px solid var(--primary-color);
  background-color: var(--background-color);
  overflow: hidden;
  z-index: 1;
  transition: background-color 0.2s ease-in-out;
}

.section .heading:after {
  content: "‏‏‎";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M 0 1 H 16 M 0 8 H 16 M 0 15 H 16' stroke='%23000000' stroke-width='1' shape-rendering='crispEdges'/%3E%3C/svg%3E");
  width: 1.6rem;
  height: 1.6rem;
  margin: 1.6rem 1.2rem;
  float: right;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.section .expanded .heading:after {
  opacity: 1;
}

.section a.heading:hover {
  background-color: var(--secondary-color);
}

.section .container {
  height: calc(100% - 4.8rem);
  overflow: auto;
}

.section .container.text .inner {
  padding: 0.75em;
}

@media only screen and (max-width: 768px) {
  .section:not(.expanded) {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .section {
    flex: 2;
    height: 100% !important;
    border-left: 1px solid var(--primary-color);
    overflow: hidden;
  }

  .section.expanded {
    flex: 6;
  }

  .section .container.text {
    width: calc(100% + 2rem);
  }

  .section .container.text .inner {
    width: calc(((100vw - 10rem) / 6) * 3);
  }
}
</style>
